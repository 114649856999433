var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId, "anti-shift"],
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: {
                              variant: "secondary",
                              size: "sm",
                              disabled:
                                _vm.contractStaffId == null ||
                                _vm.contractStaffId.includes(
                                  "CONTRACT_STAFF_NEW_"
                                ),
                            },
                            on: {
                              click: function ($event) {
                                _vm.state.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.isAccessDenied && !_vm.isGeneric
                    ? _c(
                        "div",
                        { staticClass: "user-buttons" },
                        [
                          _vm.canAdd("USER") &&
                          _vm.id.indexOf("STAFF_NEW_") === -1 &&
                          !_vm.hasUserAccount
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-1",
                                  attrs: { size: "sm", variant: "secondary" },
                                  on: { click: _vm.createUserAccount },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("button.create_user_account")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.canEdit("USER") &&
                          _vm.id.indexOf("STAFF_NEW_") === -1 &&
                          _vm.hasUserAccount
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-1",
                                  attrs: { size: "sm", variant: "secondary" },
                                  on: { click: _vm.editUserAccount },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("button.edit_user_account")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.canDelete("USER") &&
                          _vm.id.indexOf("STAFF_NEW_") === -1 &&
                          _vm.hasUserAccount
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-1",
                                  attrs: { size: "sm", variant: "secondary" },
                                  on: { click: _vm.deleteUserAccount },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("button.delete_user_account")
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.state.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: { size: "sm", variant: "success" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.preOk.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: cancel },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("staff.title").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("staff.title").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _vm.exists && !_vm.isGeneric
                  ? _c(
                      "div",
                      { staticClass: "container pl-0" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass: "mr-1",
                                    attrs: { for: "staffContract" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("staff.field.contract"))
                                    ),
                                  ]
                                ),
                                _vm.canAdd(_vm.permissionName, [
                                  "startDate",
                                  "endDate",
                                  "staffType",
                                  "email",
                                  "firstName",
                                  "payAmount",
                                  "payCurrency",
                                ])
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-action",
                                        attrs: {
                                          id: "CONTRACT_ADD",
                                          disabled: _vm.disableContractAddBtn,
                                        },
                                        on: { click: _vm.contractStaffAdd },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "plus"] },
                                        }),
                                        _c("b-popover", {
                                          attrs: {
                                            target: "CONTRACT_ADD",
                                            placement: "top",
                                            triggers: "hover",
                                            boundary: "viewport",
                                            content: _vm.$t(
                                              "staff.button.contract_add"
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.canDelete(_vm.permissionName)
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-action",
                                        attrs: {
                                          id: "CONTRACT_REMOVE",
                                          disabled:
                                            _vm.disableContractRemoveBtn,
                                        },
                                        on: { click: _vm.contractStaffRemove },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["far", "trash-can"] },
                                        }),
                                        _c("b-popover", {
                                          attrs: {
                                            target: "CONTRACT_REMOVE",
                                            placement: "top",
                                            triggers: "hover",
                                            boundary: "viewport",
                                            content: _vm.$t(
                                              "staff.button.contract_remove"
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-form-group",
                                  { attrs: { id: "field-contract" } },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.contractStaffId,
                                            expression: "contractStaffId",
                                          },
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: { required: true },
                                            expression: "{ required: true }",
                                          },
                                        ],
                                        staticClass: "custom-select",
                                        attrs: {
                                          id: "staffContract",
                                          "data-vv-as": _vm.$t(
                                            "staff.field.contract"
                                          ),
                                          "data-vv-name": "contractStaffId",
                                          "data-vv-delay": "500",
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.contractStaffId = $event
                                                .target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            },
                                            _vm.onStaffContractChanged,
                                          ],
                                        },
                                      },
                                      [
                                        _vm._l(
                                          _vm.contracts,
                                          function (opt, index) {
                                            return [
                                              opt.uuId.includes(
                                                "CONTRACT_STAFF_NEW_"
                                              )
                                                ? _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: opt.uuId,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "staff.contract_new"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: opt.uuId,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getContractOptionLabel(
                                                            opt,
                                                            index
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showStaffTypeError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "staff.staffType"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isAvatarBannerVisible
                  ? _c("AvatarBanner", {
                      attrs: {
                        readOnly: _vm.isAvatarBannerReadOnly,
                        baseAvatarIcon: ["fad", "user-tie"],
                      },
                      on: { status: _vm.avatarBannerStatus },
                      model: {
                        value: _vm.avatarBanner,
                        callback: function ($$v) {
                          _vm.avatarBanner = $$v
                        },
                        expression: "avatarBanner",
                      },
                    })
                  : _vm._e(),
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: _vm.alertError ? "danger" : "success",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: {
                        icon: _vm.alertError
                          ? ["fas", "triangle-exclamation"]
                          : ["far", "check"],
                      },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isGeneric
                          ? [
                              _vm.isFirstNameVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "8" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t("staff.field.name"),
                                            "label-for": "firstName",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "firstName",
                                                  type: "text",
                                                  "data-vv-as":
                                                    _vm.$t("staff.field.name"),
                                                  "data-vv-name":
                                                    "staff.firstName",
                                                  "data-vv-delay": "500",
                                                  readonly:
                                                    _vm.isFirstNameReadOnly,
                                                  trim: "",
                                                  state:
                                                    _vm.fieldValidateUtil.stateValidate(
                                                      _vm.isReadOnly,
                                                      _vm.veeFields,
                                                      _vm.errors,
                                                      "staff.firstName"
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.staff.firstName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      "firstName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "staff.firstName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block": _vm.showNameError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.firstName"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["name"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["name"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "name" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isIdentifierVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "4" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t("field.identifier"),
                                            "label-for": "identifier",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "identifier",
                                                  type: "text",
                                                  "data-vv-as":
                                                    _vm.$t("field.identifier"),
                                                  "data-vv-name":
                                                    "staff.identifier",
                                                  maxlength:
                                                    _vm.maxIdentifierLength,
                                                  disabled:
                                                    _vm.isIdentifierReadOnly,
                                                  trim: "",
                                                },
                                                model: {
                                                  value: _vm.staff.identifier,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      "identifier",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff.identifier",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["email"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["email"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "email" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["firstName"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["firstName"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "firstName" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["lastName"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["lastName"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "lastName" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["identifier"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["identifier"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "identifier" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["default"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["default"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isCompanyVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.company")
                                              )
                                            ),
                                          ]),
                                          !_vm.isCompanyReadOnly
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: { id: "COMPANY_ADD" },
                                                  on: {
                                                    click:
                                                      _vm.companySelectorToggle,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target: "COMPANY_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      content: _vm.$t(
                                                        "staff.button.company_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("BadgeGroup", {
                                            attrs: {
                                              readOnly: _vm.isCompanyReadOnly,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      _c("Badge", {
                                                        key: index,
                                                        attrs: {
                                                          text: item.name,
                                                          variant: "primary",
                                                          pillable:
                                                            !!item.pillable,
                                                          readOnly:
                                                            _vm.isCompanyReadOnly,
                                                        },
                                                        on: {
                                                          badgeRemove:
                                                            function ($event) {
                                                              return _vm.companyBadgeRemove(
                                                                index
                                                              )
                                                            },
                                                          badgeClick: function (
                                                            $event
                                                          ) {
                                                            return _vm.companyBadgeClick(
                                                              item.uuId
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3411449927
                                            ),
                                            model: {
                                              value: _vm.company,
                                              callback: function ($$v) {
                                                _vm.company = $$v
                                              },
                                              expression: "company",
                                            },
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "mt-1 alert-danger form-field-alert",
                                              class: {
                                                "d-block": _vm.showCompanyError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.company"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["companies"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["companies"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "companies" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _vm.isEmailVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "8" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t("staff.field.email"),
                                            "label-for": "email",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "envelope",
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "email",
                                                  type: "text",
                                                  "data-vv-as":
                                                    _vm.$t("staff.field.email"),
                                                  "data-vv-name": "staff.email",
                                                  "data-vv-delay": "500",
                                                  readonly: _vm.isEmailReadOnly,
                                                  trim: "",
                                                  state:
                                                    _vm.fieldValidateUtil.stateValidate(
                                                      _vm.isReadOnly,
                                                      _vm.veeFields,
                                                      _vm.errors,
                                                      "staff.email"
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.staff.email,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      "email",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "staff.email",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block": _vm.showEmailError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.email"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["email"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["email"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "email" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isIdentifierVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "4" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t("field.identifier"),
                                            "label-for": "identifier",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "identifier",
                                                  type: "text",
                                                  "data-vv-as":
                                                    _vm.$t("field.identifier"),
                                                  "data-vv-name":
                                                    "staff.identifier",
                                                  maxlength:
                                                    _vm.maxIdentifierLength,
                                                  readonly:
                                                    _vm.isIdentifierReadOnly,
                                                  trim: "",
                                                },
                                                model: {
                                                  value: _vm.staff.identifier,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      "identifier",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff.identifier",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["identifier"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["identifier"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "identifier" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["default"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["default"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isFirstNameVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "staff.field.firstName"
                                            ),
                                            "label-for": "firstName",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "firstName",
                                                  type: "text",
                                                  "data-vv-as": _vm.$t(
                                                    "staff.field.firstName"
                                                  ),
                                                  "data-vv-name":
                                                    "staff.firstName",
                                                  "data-vv-delay": "500",
                                                  readonly:
                                                    _vm.isFirstNameReadOnly,
                                                  trim: "",
                                                  state:
                                                    _vm.fieldValidateUtil.stateValidate(
                                                      _vm.isReadOnly,
                                                      _vm.veeFields,
                                                      _vm.errors,
                                                      "staff.firstName"
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.staff.firstName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      "firstName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "staff.firstName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block": _vm.showNameError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.firstName"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["firstName"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["firstName"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "firstName" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isLastNameVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "staff.field.lastName"
                                            ),
                                            "label-for": "lastName",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "lastName",
                                                  type: "text",
                                                  "data-vv-as": _vm.$t(
                                                    "staff.field.lastName"
                                                  ),
                                                  "data-vv-name":
                                                    "staff.lastName",
                                                  "data-vv-delay": "500",
                                                  readonly:
                                                    _vm.isLastNameReadOnly,
                                                  trim: "",
                                                  state:
                                                    _vm.fieldValidateUtil.stateValidate(
                                                      _vm.isReadOnly,
                                                      _vm.veeFields,
                                                      _vm.errors,
                                                      "staff.lastName"
                                                    ),
                                                },
                                                model: {
                                                  value: _vm.staff.lastName,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      "lastName",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "staff.lastName",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block":
                                                  _vm.showFamilyNameError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.lastName"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["lastName"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["lastName"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "lastName" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["name"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["name"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "name" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isCompanyVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.company")
                                              )
                                            ),
                                          ]),
                                          !_vm.isCompanyReadOnly
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: { id: "COMPANY_ADD" },
                                                  on: {
                                                    click:
                                                      _vm.companySelectorToggle,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target: "COMPANY_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      content: _vm.$t(
                                                        "staff.button.company_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("BadgeGroup", {
                                            attrs: {
                                              readOnly: _vm.isCompanyReadOnly,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      _c("Badge", {
                                                        key: index,
                                                        attrs: {
                                                          text: item.name,
                                                          variant: "primary",
                                                          pillable:
                                                            !!item.pillable,
                                                          readOnly:
                                                            _vm.isCompanyReadOnly,
                                                        },
                                                        on: {
                                                          badgeRemove:
                                                            function ($event) {
                                                              return _vm.companyBadgeRemove(
                                                                index
                                                              )
                                                            },
                                                          badgeClick: function (
                                                            $event
                                                          ) {
                                                            return _vm.companyBadgeClick(
                                                              item.uuId
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3411449927
                                            ),
                                            model: {
                                              value: _vm.company,
                                              callback: function ($$v) {
                                                _vm.company = $$v
                                              },
                                              expression: "company",
                                            },
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "mt-1 alert-danger form-field-alert",
                                              class: {
                                                "d-block": _vm.showCompanyError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.company"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["companies"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["companies"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "companies" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ],
                        _c(
                          "b-col",
                          {
                            staticClass: "pr-0",
                            attrs: { cols: "12", if: "isDepartmentVisible" },
                          },
                          [
                            _c(
                              "b-form-group",
                              [
                                _c("label", { staticClass: "mr-1" }, [
                                  _vm._v(
                                    _vm._s(_vm.$t("staff.field.departments"))
                                  ),
                                ]),
                                !_vm.isDepartmentReadOnly
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn-action",
                                        attrs: {
                                          disabled: _vm.company.length === 0,
                                          id: "DEPARTMENT_ADD",
                                        },
                                        on: { click: _vm.depSelectorToggle },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          class:
                                            _vm.company.length === 0
                                              ? "greyed"
                                              : "",
                                          attrs: { icon: ["far", "plus"] },
                                        }),
                                        _c("b-popover", {
                                          attrs: {
                                            target: "DEPARTMENT_ADD",
                                            placement: "top",
                                            triggers: "hover",
                                            content: _vm.$t(
                                              "staff.button.department_add"
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("BadgeGroup", {
                                  attrs: { readOnly: _vm.isDepartmentReadOnly },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          _c("Badge", {
                                            key: index,
                                            attrs: {
                                              text: "" + item.name,
                                              variant: "primary",
                                              pillable: !!item.pillable,
                                              readOnly:
                                                _vm.isDepartmentReadOnly,
                                            },
                                            on: {
                                              badgeRemove: function ($event) {
                                                return _vm.depBadgeRemove(index)
                                              },
                                              badgeClick: function ($event) {
                                                return _vm.depBadgeClick(
                                                  item.uuId
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.departments,
                                    callback: function ($$v) {
                                      _vm.departments = $$v
                                    },
                                    expression: "departments",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.customFieldMap["departments"] != null
                          ? _vm._l(
                              _vm.customFieldMap["departments"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "departments" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        !_vm.isGeneric
                          ? [
                              _vm.isPositionVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "staff.field.position"
                                            ),
                                            "label-for": "position",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "position",
                                                  type: "text",
                                                  "data-vv-as": _vm.$t(
                                                    "staff.field.position"
                                                  ),
                                                  "data-vv-name":
                                                    "staff.position",
                                                  "data-vv-delay": "500",
                                                  readonly:
                                                    _vm.isPositionReadOnly,
                                                  trim: "",
                                                },
                                                model: {
                                                  value: _vm.staff.position,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      "position",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "staff.position",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["position"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["position"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "position" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isStaffTypeVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            id: "field-type",
                                            label: _vm.$t("staff.field.type"),
                                            "label-for": "staffType",
                                          },
                                        },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.staff.staffType,
                                                  expression: "staff.staffType",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: { required: true },
                                                  expression:
                                                    "{ required: true }",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              attrs: {
                                                id: "staffType",
                                                disabled:
                                                  _vm.isStaffTypeReadOnly,
                                                "data-vv-as":
                                                  _vm.$t("staff.field.type"),
                                                "data-vv-name":
                                                  "staff.staffType",
                                                "data-vv-delay": "500",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.staff,
                                                    "staffType",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.typeOptions,
                                                function (opt, index) {
                                                  return [
                                                    _c(
                                                      "option",
                                                      {
                                                        key: index,
                                                        style: {
                                                          display:
                                                            opt.num < 0
                                                              ? "none"
                                                              : "block",
                                                        },
                                                        domProps: {
                                                          value: opt.value,
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(opt.text))]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block":
                                                  _vm.showStaffTypeError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.staffType"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["type"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["type"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "type" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isLocationVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.location")
                                              )
                                            ),
                                          ]),
                                          !_vm.isLocationReadOnly
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: { id: "LOCATION_ADD" },
                                                  on: {
                                                    click: _vm.locSelectorOpen,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target: "LOCATION_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      content: _vm.$t(
                                                        "staff.button.location_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("BadgeGroup", {
                                            attrs: {
                                              readOnly: _vm.isLocationReadOnly,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      _c("Badge", {
                                                        key: index,
                                                        attrs: {
                                                          text: item.name,
                                                          variant: "primary",
                                                          pillable:
                                                            !!item.pillable,
                                                          readOnly:
                                                            _vm.isLocationReadOnly,
                                                        },
                                                        on: {
                                                          badgeRemove:
                                                            function ($event) {
                                                              return _vm.locationBadgeRemove(
                                                                index
                                                              )
                                                            },
                                                          badgeClick: function (
                                                            $event
                                                          ) {
                                                            return _vm.locationBadgeClick(
                                                              item.uuId
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1048688413
                                            ),
                                            model: {
                                              value: _vm.location,
                                              callback: function ($$v) {
                                                _vm.location = $$v
                                              },
                                              expression: "location",
                                            },
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block":
                                                  _vm.showLocationError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first("location")
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["locations"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["locations"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "locations" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _vm.customFieldMap["position"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["position"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "position" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isLocationVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.location")
                                              )
                                            ),
                                          ]),
                                          !_vm.isLocationReadOnly
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: { id: "LOCATION_ADD" },
                                                  on: {
                                                    click: _vm.locSelectorOpen,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target: "LOCATION_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      content: _vm.$t(
                                                        "staff.button.location_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("BadgeGroup", {
                                            attrs: {
                                              readOnly: _vm.isLocationReadOnly,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      _c("Badge", {
                                                        key: index,
                                                        attrs: {
                                                          text: item.name,
                                                          variant: "primary",
                                                          pillable:
                                                            !!item.pillable,
                                                          readOnly:
                                                            _vm.isLocationReadOnly,
                                                        },
                                                        on: {
                                                          badgeRemove:
                                                            function ($event) {
                                                              return _vm.locationBadgeRemove(
                                                                index
                                                              )
                                                            },
                                                          badgeClick: function (
                                                            $event
                                                          ) {
                                                            _vm.canView(
                                                              "LOCATION"
                                                            )
                                                              ? _vm.locationBadgeClick(
                                                                  item.uuId
                                                                )
                                                              : ""
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2275913295
                                            ),
                                            model: {
                                              value: _vm.location,
                                              callback: function ($$v) {
                                                _vm.location = $$v
                                              },
                                              expression: "location",
                                            },
                                          }),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block":
                                                  _vm.showLocationError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first("location")
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["locations"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["locations"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "locations" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isStaffTypeVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          staticStyle: { "padding-top": "6px" },
                                          attrs: {
                                            id: "field-type",
                                            label: _vm.$t("staff.field.type"),
                                            "label-for": "staffType",
                                          },
                                        },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.staff.staffType,
                                                  expression: "staff.staffType",
                                                },
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: { required: true },
                                                  expression:
                                                    "{ required: true }",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              attrs: {
                                                id: "staffType",
                                                disabled:
                                                  _vm.isStaffTypeReadOnly,
                                                "data-vv-as":
                                                  _vm.$t("staff.field.type"),
                                                "data-vv-name":
                                                  "staff.staffType",
                                                "data-vv-delay": "500",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.staff,
                                                    "staffType",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.typeOptions,
                                                function (opt, index) {
                                                  return [
                                                    _c(
                                                      "option",
                                                      {
                                                        key: index,
                                                        style: {
                                                          display:
                                                            opt.num < 0
                                                              ? "none"
                                                              : "block",
                                                        },
                                                        domProps: {
                                                          value: opt.value,
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(opt.text))]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "b-form-invalid-feedback",
                                            {
                                              staticClass:
                                                "alert-danger form-field-alert",
                                              class: {
                                                "d-block":
                                                  _vm.showStaffTypeError,
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "circle-exclamation",
                                                  ],
                                                },
                                              }),
                                              _vm._v(
                                                "  " +
                                                  _vm._s(
                                                    _vm.errors.first(
                                                      "staff.staffType"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["type"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["type"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "type" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ],
                        !_vm.isGeneric
                          ? [
                              _vm.isPhonesVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-0" },
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.phones")
                                              )
                                            ),
                                          ]),
                                          _vm._l(
                                            _vm.staff.phones,
                                            function (phone, index) {
                                              return _c(
                                                "b-row",
                                                { key: index },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { lg: "" } },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              !(
                                                                _vm.isPhonesReadOnly &&
                                                                phone.kind ==
                                                                  null
                                                              )
                                                                ? _c(
                                                                    "b-input-group-prepend",
                                                                    [
                                                                      _c(
                                                                        "b-form-select",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              options:
                                                                                _vm.optionPhoneNrs,
                                                                              disabled:
                                                                                _vm.isPhonesReadOnly,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                phone.kind,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    phone,
                                                                                    "kind",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "phone.kind",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "template",
                                                                            {
                                                                              slot: "first",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "option",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        "",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        null,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "staff.select_phone_type"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    readonly:
                                                                      _vm.isPhonesReadOnly,
                                                                    trim: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      phone.data,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          phone,
                                                                          "data",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "phone.data",
                                                                  },
                                                                }
                                                              ),
                                                              !_vm.isPhonesReadOnly
                                                                ? [
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id:
                                                                                  "PHONE_BTN_ADD_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                variant:
                                                                                  "secondary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fieldItemAdd(
                                                                                    "phones",
                                                                                    index,
                                                                                    {
                                                                                      kind: null,
                                                                                      data: null,
                                                                                    }
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: [
                                                                                      "far",
                                                                                      "plus",
                                                                                    ],
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-popover",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                target:
                                                                                  "PHONE_BTN_ADD_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                triggers:
                                                                                  "hover",
                                                                                placement:
                                                                                  "top",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "button.add"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id:
                                                                                  "PHONE_BTN_REMOVE_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                variant:
                                                                                  "secondary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fieldItemRemove(
                                                                                    "phones",
                                                                                    index,
                                                                                    {
                                                                                      kind: null,
                                                                                      data: null,
                                                                                    }
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: [
                                                                                      "far",
                                                                                      "trash-can",
                                                                                    ],
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-popover",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                target:
                                                                                  "PHONE_BTN_REMOVE_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                triggers:
                                                                                  "hover",
                                                                                placement:
                                                                                  "top",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "button.remove"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["phones"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["phones"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "phones" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isWebsitesVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-0" },
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.websites")
                                              )
                                            ),
                                          ]),
                                          _vm._l(
                                            _vm.staff.websites,
                                            function (website, index) {
                                              return _c(
                                                "b-row",
                                                { key: index },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { lg: "" } },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    id:
                                                                      "website" +
                                                                      index,
                                                                    readonly:
                                                                      _vm.isWebsitesReadOnly,
                                                                    trim: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.staff
                                                                        .websites[
                                                                        index
                                                                      ],
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .staff
                                                                            .websites,
                                                                          index,
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "staff.websites[index]",
                                                                  },
                                                                }
                                                              ),
                                                              !_vm.isWebsitesReadOnly
                                                                ? [
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id:
                                                                                  "WEBSITE_BTN_ADD_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                variant:
                                                                                  "secondary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fieldItemAdd(
                                                                                    "websites",
                                                                                    index,
                                                                                    null
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: [
                                                                                      "far",
                                                                                      "plus",
                                                                                    ],
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-popover",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                target:
                                                                                  "WEBSITE_BTN_ADD_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                triggers:
                                                                                  "hover",
                                                                                placement:
                                                                                  "top",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "button.add"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id:
                                                                                  "WEBSITE_BTN_REMOVE_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                variant:
                                                                                  "secondary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fieldItemRemove(
                                                                                    "websites",
                                                                                    index,
                                                                                    null
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: [
                                                                                      "far",
                                                                                      "trash-can",
                                                                                    ],
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-popover",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                target:
                                                                                  "WEBSITE_BTN_REMOVE_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                triggers:
                                                                                  "hover",
                                                                                placement:
                                                                                  "top",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "button.remove"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["websites"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["websites"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "websites" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isSocialsVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        { staticClass: "mb-0" },
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.socials")
                                              )
                                            ),
                                          ]),
                                          _vm._l(
                                            _vm.staff.socials,
                                            function (social, index) {
                                              return _c(
                                                "b-row",
                                                { key: index },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { lg: "" } },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              !(
                                                                _vm.isSocialsReadOnly &&
                                                                social.kind ==
                                                                  null
                                                              )
                                                                ? _c(
                                                                    "b-input-group-prepend",
                                                                    [
                                                                      _c(
                                                                        "b-form-select",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              options:
                                                                                _vm.optionSocials,
                                                                              disabled:
                                                                                _vm.isSocialsReadOnly,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                social.kind,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    social,
                                                                                    "kind",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "social.kind",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "template",
                                                                            {
                                                                              slot: "first",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "option",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        "",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        null,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "staff.select_socialnet_type"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    type: "text",
                                                                    readonly:
                                                                      _vm.isSocialsReadOnly,
                                                                    trim: "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      social.data,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          social,
                                                                          "data",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "social.data",
                                                                  },
                                                                }
                                                              ),
                                                              !_vm.isSocialsReadOnly
                                                                ? [
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id:
                                                                                  "SOCIAL_BTN_ADD_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                variant:
                                                                                  "secondary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fieldItemAdd(
                                                                                    "socials",
                                                                                    index,
                                                                                    {
                                                                                      kind: null,
                                                                                      data: null,
                                                                                    }
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: [
                                                                                      "far",
                                                                                      "plus",
                                                                                    ],
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-popover",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                target:
                                                                                  "SOCIAL_BTN_ADD_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                triggers:
                                                                                  "hover",
                                                                                placement:
                                                                                  "top",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "button.add"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "b-input-group-append",
                                                                      [
                                                                        _c(
                                                                          "b-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                id:
                                                                                  "SOCIAL_BTN_REMOVE_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                variant:
                                                                                  "secondary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.fieldItemRemove(
                                                                                    "socials",
                                                                                    index,
                                                                                    {
                                                                                      kind: null,
                                                                                      data: null,
                                                                                    }
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "font-awesome-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    icon: [
                                                                                      "far",
                                                                                      "trash-can",
                                                                                    ],
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "b-popover",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                target:
                                                                                  "SOCIAL_BTN_REMOVE_" +
                                                                                  _vm.id +
                                                                                  "_" +
                                                                                  index,
                                                                                triggers:
                                                                                  "hover",
                                                                                placement:
                                                                                  "top",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "button.remove"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                : _vm._e(),
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["socials"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["socials"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "socials" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isStartDateVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "project.field.scheduleStart"
                                            ),
                                            "label-for": "startDate",
                                          },
                                        },
                                        [
                                          _c("b-form-datepicker", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "startDate",
                                              max: _vm.staff.endDate,
                                              "value-as-date": "",
                                              "today-button": "",
                                              "reset-button": "",
                                              "close-button": "",
                                              "hide-header": "",
                                              "label-today-button":
                                                _vm.$t("date.today"),
                                              "label-reset-button":
                                                _vm.$t("date.reset"),
                                              "label-close-button":
                                                _vm.$t("date.close"),
                                              "today-button-variant": "primary",
                                              "reset-button-variant": "danger",
                                              "close-button-variant":
                                                "secondary",
                                              readonly: _vm.isStartDateReadOnly,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function (ref) {
                                                    return [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "calendar-days",
                                                          ],
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2621928167
                                            ),
                                            model: {
                                              value: _vm.staff.startDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.staff,
                                                  "startDate",
                                                  $$v
                                                )
                                              },
                                              expression: "staff.startDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["startDate"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["startDate"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "startDate" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.isEndDateVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.$t(
                                              "project.field.scheduleFinish"
                                            ),
                                            "label-for": "endDate",
                                          },
                                        },
                                        [
                                          _c("b-form-datepicker", {
                                            staticClass: "mb-2",
                                            attrs: {
                                              id: "endDate",
                                              min: _vm.staff.startDate,
                                              "value-as-date": "",
                                              "today-button": "",
                                              "reset-button": "",
                                              "close-button": "",
                                              "hide-header": "",
                                              "label-today-button":
                                                _vm.$t("date.today"),
                                              "label-reset-button":
                                                _vm.$t("date.reset"),
                                              "label-close-button":
                                                _vm.$t("date.close"),
                                              "today-button-variant": "primary",
                                              "reset-button-variant": "danger",
                                              "close-button-variant":
                                                "secondary",
                                              readonly: _vm.isEndDateReadOnly,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function (ref) {
                                                    return [
                                                      _c("font-awesome-icon", {
                                                        attrs: {
                                                          icon: [
                                                            "far",
                                                            "calendar-days",
                                                          ],
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2621928167
                                            ),
                                            model: {
                                              value: _vm.staff.endDate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.staff,
                                                  "endDate",
                                                  $$v
                                                )
                                              },
                                              expression: "staff.endDate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["endDate"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["endDate"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "endDate" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _vm.customFieldMap["phones"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["phones"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "phones" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["websites"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["websites"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "websites" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["socials"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["socials"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "socials" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["startDate"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["startDate"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "startDate" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _vm.customFieldMap["endDate"] != null
                                ? _vm._l(
                                    _vm.customFieldMap["endDate"],
                                    function (field, index) {
                                      return _c(
                                        "b-col",
                                        {
                                          key: "endDate" + index,
                                          staticClass: "pr-0",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              field.type !== "Boolean"
                                                ? _c(
                                                    "template",
                                                    { slot: "label" },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              field.displayName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      field.description
                                                        ? _c(
                                                            "span",
                                                            [
                                                              _c(
                                                                "font-awesome-icon",
                                                                {
                                                                  style: {
                                                                    color:
                                                                      "var(--form-control-placeholder)",
                                                                    fontSize:
                                                                      "0.9em",
                                                                  },
                                                                  attrs: {
                                                                    id:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    icon: [
                                                                      "far",
                                                                      "circle-question",
                                                                    ],
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-popover",
                                                                {
                                                                  attrs: {
                                                                    target:
                                                                      _vm.componentId +
                                                                      "_" +
                                                                      field.name,
                                                                    triggers:
                                                                      "hover",
                                                                    placement:
                                                                      "top",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        field.description
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c("CustomField", {
                                                attrs: {
                                                  componentId: _vm.componentId,
                                                  field: field,
                                                  disabled:
                                                    _vm.isReadOnly ||
                                                    (_vm.exists &&
                                                      !_vm.canEdit(
                                                        _vm.permissionName,
                                                        [field.name]
                                                      )),
                                                },
                                                model: {
                                                  value: _vm.staff[field.name],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.staff,
                                                      field.name,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "staff[field.name]",
                                                },
                                              }),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                            ],
                        _vm.isSkillVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: 12 } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.field.skills"))
                                      ),
                                    ]),
                                    !_vm.isSkillReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "SKILL_ADD" },
                                            on: {
                                              click: _vm.skillSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "SKILL_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "staff.button.skill_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: { readOnly: _vm.isSkillReadOnly },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text:
                                                      item.name +
                                                      " (" +
                                                      item.level +
                                                      ")",
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isSkillReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.skillBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.skillBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        633418557
                                      ),
                                      model: {
                                        value: _vm.skills,
                                        callback: function ($$v) {
                                          _vm.skills = $$v
                                        },
                                        expression: "skills",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["skills"] != null
                          ? _vm._l(
                              _vm.customFieldMap["skills"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "skills" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isResourceVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { staticClass: "mr-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.field.resources"))
                                      ),
                                    ]),
                                    !_vm.isResourceReadOnly
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "btn-action",
                                            attrs: { id: "RESOURCE_ADD" },
                                            on: {
                                              click: _vm.resourceSelectorToggle,
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: ["far", "plus"] },
                                            }),
                                            _c("b-popover", {
                                              attrs: {
                                                target: "RESOURCE_ADD",
                                                placement: "top",
                                                triggers: "hover",
                                                content: _vm.$t(
                                                  "staff.button.resource_add"
                                                ),
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("BadgeGroup", {
                                      attrs: {
                                        readOnly: _vm.isResourceReadOnly,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var item = ref.item
                                              var index = ref.index
                                              return [
                                                _c("Badge", {
                                                  key: index,
                                                  attrs: {
                                                    text:
                                                      item.name +
                                                      " (" +
                                                      (item.quantity > 1
                                                        ? item.quantity + " x "
                                                        : "") +
                                                      (item.utilization
                                                        ? item.utilization * 100
                                                        : 100) +
                                                      "%)",
                                                    variant: "primary",
                                                    pillable: !!item.pillable,
                                                    readOnly:
                                                      _vm.isResourceReadOnly,
                                                  },
                                                  on: {
                                                    badgeRemove: function (
                                                      $event
                                                    ) {
                                                      return _vm.resourceBadgeRemove(
                                                        index
                                                      )
                                                    },
                                                    badgeClick: function (
                                                      $event
                                                    ) {
                                                      return _vm.resourceBadgeClick(
                                                        item.uuId
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1272292794
                                      ),
                                      model: {
                                        value: _vm.resources,
                                        callback: function ($$v) {
                                          _vm.resources = $$v
                                        },
                                        expression: "resources",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["resources"] != null
                          ? _vm._l(
                              _vm.customFieldMap["resources"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "resources" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPayAmountVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: {
                                  cols: "12",
                                  md: _vm.isColorVisible ? 8 : 12,
                                },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("staff.field.payAmount"),
                                      "label-for": "payAmount",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "payAmount",
                                            type: "number",
                                            readonly: _vm.isPayAmountReadOnly,
                                          },
                                          model: {
                                            value: _vm.staff.payAmount,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                "payAmount",
                                                $$v
                                              )
                                            },
                                            expression: "staff.payAmount",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["payAmount"] != null
                          ? _vm._l(
                              _vm.customFieldMap["payAmount"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "payAmount" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isColorVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "color-container" },
                                  [
                                    _c("Color", {
                                      attrs: {
                                        disabled: _vm.isColorReadOnly,
                                        update: _vm.updatedColor,
                                      },
                                      model: {
                                        value: _vm.staff.color,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.staff, "color", $$v)
                                        },
                                        expression: "staff.color",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.customFieldMap["color"] != null
                          ? _vm._l(
                              _vm.customFieldMap["color"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "color" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPayCurrencyVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: {
                                  cols: "12",
                                  md: _vm.isGeneric
                                    ? _vm.isResourceQuotaVisible
                                      ? 8
                                      : 12
                                    : 12,
                                },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("staff.field.payCurrency"),
                                      "label-for": "payCurrency",
                                      size: "sm",
                                    },
                                  },
                                  [
                                    _c("b-input-group", [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.staff.payCurrency,
                                              expression: "staff.payCurrency",
                                            },
                                          ],
                                          staticClass: "custom-select",
                                          attrs: {
                                            id: "payCurrency",
                                            disabled: _vm.isPayCurrencyReadOnly,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.staff,
                                                "payCurrency",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.currencyOpts,
                                            function (opt, index) {
                                              return [
                                                _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    style: {
                                                      display:
                                                        opt.num < 0
                                                          ? "none"
                                                          : "block",
                                                    },
                                                    domProps: {
                                                      value: opt.value,
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(opt.text))]
                                                ),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["payCurrency"] != null
                          ? _vm._l(
                              _vm.customFieldMap["payCurrency"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "payCurrency" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isGeneric && _vm.isResourceQuotaVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "4" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "staff.field.resourceQuota"
                                      ),
                                      "label-for": "resourceQuota",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        !_vm.isResourceQuotaReadOnly
                                          ? _c(
                                              "b-input-group-prepend",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      id: "QUOTA_SUBTRACT",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.quotaAddMinus(
                                                          -1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "minus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target:
                                                          "QUOTA_SUBTRACT",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "staff.button.quota_subtract"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("b-form-input", {
                                          staticClass: "form-label",
                                          attrs: {
                                            type: "text",
                                            readonly:
                                              _vm.isResourceQuotaReadOnly,
                                          },
                                          on: { blur: _vm.onQuotaBlur },
                                          model: {
                                            value: _vm.staff.resourceQuota,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                "resourceQuota",
                                                $$v
                                              )
                                            },
                                            expression: "staff.resourceQuota",
                                          },
                                        }),
                                        !_vm.isResourceQuotaReadOnly
                                          ? _c(
                                              "b-input-group-append",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: { id: "QUOTA_ADD" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.quotaAddMinus(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["far", "plus"],
                                                      },
                                                    }),
                                                    _c("b-popover", {
                                                      attrs: {
                                                        target: "QUOTA_ADD",
                                                        placement: "top",
                                                        triggers: "hover",
                                                        content: _vm.$t(
                                                          "staff.button.quota_add"
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block":
                                            _vm.showStaffResourceQuotaError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "staff.resourceQuota"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["resourceQuota"] != null
                          ? _vm._l(
                              _vm.customFieldMap["resourceQuota"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "resourceQuota" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isPayFrequencyVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("staff.field.payFrequency"),
                                      "label-for": "payFrequency",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-select", {
                                          attrs: {
                                            id: "payFrequency",
                                            options: _vm.payFrequencyOptions,
                                            disabled:
                                              _vm.isPayFrequencyReadOnly,
                                          },
                                          model: {
                                            value: _vm.staff.payFrequency,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                "payFrequency",
                                                $$v
                                              )
                                            },
                                            expression: "staff.payFrequency",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: {
                                          "d-block": _vm.showPayFrequencyError,
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "staff.payFrequency"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["payFrequency"] != null
                          ? _vm._l(
                              _vm.customFieldMap["payFrequency"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "payFrequency" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        !_vm.isGeneric
                          ? [
                              _vm.isStorageFileVisible
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pr-0",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _c(
                                        "b-form-group",
                                        [
                                          _c("label", { staticClass: "mr-1" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("staff.field.files")
                                              )
                                            ),
                                          ]),
                                          !_vm.isStorageFileReadOnly
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass: "btn-action",
                                                  attrs: { id: "FILE_ADD" },
                                                  on: {
                                                    click:
                                                      _vm.fileSelectorToggle,
                                                  },
                                                },
                                                [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: ["far", "plus"],
                                                    },
                                                  }),
                                                  _c("b-popover", {
                                                    attrs: {
                                                      target: "FILE_ADD",
                                                      placement: "top",
                                                      triggers: "hover",
                                                      content: _vm.$t(
                                                        "staff.button.file_add"
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("BadgeGroup", {
                                            attrs: {
                                              readOnly:
                                                _vm.isStorageFileReadOnly,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    var index = ref.index
                                                    return [
                                                      _c("Badge", {
                                                        key: index,
                                                        attrs: {
                                                          text: _vm.labelFilename(
                                                            item.name,
                                                            item.type
                                                          ),
                                                          variant: "primary",
                                                          pillable:
                                                            !!item.pillable,
                                                          readOnly:
                                                            _vm.isStorageFileReadOnly,
                                                          enableClickWhenReadOnly:
                                                            "",
                                                        },
                                                        on: {
                                                          badgeRemove:
                                                            function ($event) {
                                                              return _vm.fileBadgeRemove(
                                                                index
                                                              )
                                                            },
                                                          badgeClick: function (
                                                            $event
                                                          ) {
                                                            return _vm.fileBadgeClick(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              1579555038
                                            ),
                                            model: {
                                              value: _vm.files,
                                              callback: function ($$v) {
                                                _vm.files = $$v
                                              },
                                              expression: "files",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm.customFieldMap["files"] != null
                          ? _vm._l(
                              _vm.customFieldMap["files"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "files" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isTagVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("TagList", {
                                      attrs: {
                                        holderId: _vm.id,
                                        tags: _vm.tags,
                                        readOnly: _vm.isTagReadOnly,
                                      },
                                      on: { modified: _vm.tagsModified },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["tags"] != null
                          ? _vm._l(
                              _vm.customFieldMap["tags"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "tags" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isCalendarVisible
                          ? [
                              _c(
                                "b-col",
                                { staticClass: "pr-0", attrs: { cols: "12" } },
                                [
                                  _c("b-form-group", [
                                    _c("label", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("staff.field.calendar"))
                                      ),
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn-action btn-lg",
                                        on: { click: _vm.showCalendar },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["fad", "calendar-days"],
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.customFieldMap["calendar"] != null
                          ? _vm._l(
                              _vm.customFieldMap["calendar"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "calendar" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                        _vm.isNoteVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("NoteList", {
                                      attrs: {
                                        readOnly: _vm.isNoteReadOnly,
                                        notes: _vm.notes,
                                      },
                                      on: {
                                        add: _vm.addNote,
                                        edit: _vm.editNote,
                                        toRemove: _vm.removeNote,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.customFieldMap["notes"] != null
                          ? _vm._l(
                              _vm.customFieldMap["notes"],
                              function (field, index) {
                                return _c(
                                  "b-col",
                                  {
                                    key: "notes" + index,
                                    staticClass: "pr-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c(
                                      "b-form-group",
                                      [
                                        field.type !== "Boolean"
                                          ? _c("template", { slot: "label" }, [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(field.displayName)
                                                  ),
                                                ]
                                              ),
                                              field.description
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("font-awesome-icon", {
                                                        style: {
                                                          color:
                                                            "var(--form-control-placeholder)",
                                                          fontSize: "0.9em",
                                                        },
                                                        attrs: {
                                                          id:
                                                            _vm.componentId +
                                                            "_" +
                                                            field.name,
                                                          icon: [
                                                            "far",
                                                            "circle-question",
                                                          ],
                                                        },
                                                      }),
                                                      _c(
                                                        "b-popover",
                                                        {
                                                          attrs: {
                                                            target:
                                                              _vm.componentId +
                                                              "_" +
                                                              field.name,
                                                            triggers: "hover",
                                                            placement: "top",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                field.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          : _vm._e(),
                                        _c("CustomField", {
                                          attrs: {
                                            componentId: _vm.componentId,
                                            field: field,
                                            disabled:
                                              _vm.isReadOnly ||
                                              (_vm.exists &&
                                                !_vm.canEdit(
                                                  _vm.permissionName,
                                                  [field.name]
                                                )),
                                          },
                                          model: {
                                            value: _vm.staff[field.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.staff,
                                                field.name,
                                                $$v
                                              )
                                            },
                                            expression: "staff[field.name]",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c("SkillSelectorModal", {
        attrs: { show: _vm.state.skillSelectorShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "skillSelectorShow", $event)
          },
          ok: _vm.skillSelectorOk,
        },
      }),
      _c("SkillLevelModal", {
        attrs: {
          show: _vm.state.skillLevelEditShow,
          uuId: _vm.skillLevelEdit.uuId,
          name: _vm.skillLevelEdit.name,
          level: _vm.skillLevelEdit.level,
          cData: _vm.skillLevelEdit.data,
          edgeName: "STAFF-SKILL",
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "skillLevelEditShow", $event)
          },
          ok: _vm.skillLevelOk,
        },
      }),
      _vm._l(_vm.toConfirmSkills, function (item, index) {
        return [
          _c("SkillLevelModal", {
            key: "skill-" + index,
            attrs: {
              show: item.show,
              uuId: item.uuId,
              name: item.name,
              level: item.level,
              cData: item,
              edgeName: "STAFF-SKILL",
              canApplyAll: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmSkillOk,
              cancel: _vm.toConfirmSkillCancel,
            },
          }),
        ]
      }),
      _c("ResourceSelectorModal", {
        attrs: { show: _vm.state.resourceSelectorShow },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "resourceSelectorShow", $event)
          },
          ok: _vm.resourceSelectorOk,
        },
      }),
      _c("ResourceUnitModal", {
        attrs: {
          show: _vm.state.resourceUnitEditShow,
          uuId: _vm.resourceUnitEdit.uuId,
          name: _vm.resourceUnitEdit.name,
          unit: _vm.resourceUnitEdit.unit,
          utilization: parseFloat(_vm.resourceUnitEdit.utilization),
          cData: _vm.resourceUnitEdit.data,
          edgeName: "STAFF-RESOURCE",
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "resourceUnitEditShow", $event)
          },
          ok: _vm.resourceUnitOk,
        },
      }),
      _vm._l(_vm.toConfirmResources, function (item, index) {
        return [
          _c("ResourceUnitModal", {
            key: "resource-" + index,
            attrs: {
              show: item.show,
              uuId: item.uuId,
              name: item.name,
              unit: parseInt(item.unit),
              utilization: parseFloat(item.utilization),
              cData: item,
              edgeName: "STAFF-RESOURCE",
              canApplyAll: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(item, "show", $event)
              },
              ok: _vm.toConfirmResourceOk,
              cancel: _vm.toConfirmResourceCancel,
            },
          }),
        ]
      }),
      _c("CompanySelectorModal", {
        attrs: {
          show: _vm.state.companySelectorShow,
          forceSingleSelection: true,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "companySelectorShow", $event)
          },
          ok: _vm.companySelectorOk,
        },
      }),
      _c("CompanyModal", {
        attrs: {
          id: _vm.companyEditId,
          show: _vm.state.companyEditShow,
          title: _vm.companyTitle,
          readOnly: _vm.isReadOnly,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "companyEditShow", $event)
          },
        },
      }),
      _c("LocationSelectorModal", {
        attrs: { show: _vm.state.locSelectorShow, multiple: false },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "locSelectorShow", $event)
          },
          ok: _vm.locSelectorOk,
        },
      }),
      _c("CalendarModal", {
        attrs: {
          readOnly: _vm.isReadOnly,
          id: _vm.id,
          calendar: _vm.calendar,
          calendarName: "staff",
          calendarOrder: _vm.calendarOrder,
          show: _vm.state.calShow,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "calShow", $event)
          },
          ok: _vm.calOk,
        },
      }),
      _vm.userShow
        ? _c("UserModal", {
            attrs: {
              staffData: _vm.userData,
              id: _vm.userId,
              show: _vm.userShow,
              title: _vm.userTitle,
            },
            on: {
              "update:show": function ($event) {
                _vm.userShow = $event
              },
              success: _vm.createUserAccountSuccess,
            },
          })
        : _vm._e(),
      _c("DepartmentSelectorModal", {
        attrs: {
          selectCompany: false,
          company: _vm.company.length > 0 ? _vm.company[0] : null,
          preselected: _vm.depPreselected,
          show: _vm.state.depSelectorShow,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "depSelectorShow", $event)
          },
          ok: _vm.depSelectorOk,
        },
      }),
      !_vm.isGeneric
        ? [
            _c("FileSelectorModal", {
              attrs: { show: _vm.state.fileSelectorShow },
              on: {
                "update:show": function ($event) {
                  return _vm.$set(_vm.state, "fileSelectorShow", $event)
                },
                ok: _vm.fileSelectorOk,
              },
            }),
            _c("DownloadProgressModal", {
              attrs: {
                show: _vm.downloadProgressShow,
                downloadPercentage: _vm.downloadPercentage,
              },
              on: {
                "update:show": function ($event) {
                  _vm.downloadProgressShow = $event
                },
                cancel: _vm.downloadCancel,
              },
            }),
          ]
        : _vm._e(),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.isGeneric ? _vm.id : _vm.contractStaffId,
                    entityType: "STAFF",
                    customFields: _vm.customFields,
                    links:
                      "COMPANY,DEPARTMENT,LOCATION,NOTE,RESOURCE,SKILL,STORAGE_FILE,TAG",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
            _vm.state.noteShow
              ? _c("NoteModal", {
                  attrs: { show: _vm.state.noteShow, note: _vm.note },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "noteShow", $event)
                    },
                    toAdd: _vm.toAddNote,
                    toUpdate: _vm.toUpdateNote,
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("staff.confirmation.title_email_change"),
            "ok-title": _vm.$t("button.continue"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmEmailChangeOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmEmailChangeOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.continue")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmEmailChangeShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmEmailChangeShow", $$v)
            },
            expression: "state.confirmEmailChangeShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$t("staff.confirmation.email_change_line1", [
                    this.originEmail,
                  ])
                )
              ),
            ]),
            _c("p", [
              _vm._v(_vm._s(_vm.$t("staff.confirmation.email_change_line2"))),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("user.confirmation.title_delete"),
            "ok-title": _vm.$t("button.confirm"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmDeleteOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.confirmDeleteOk },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmDeleteShow,
            callback: function ($$v) {
              _vm.confirmDeleteShow = $$v
            },
            expression: "confirmDeleteShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("staff.delete_user")))]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t(
              "staff.confirmation.title_confirm_save_before_proceed"
            ),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { close: _vm.confirmSaveBeforeContractCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSaveBeforeContractOk(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.save")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmSaveBeforeContractOk(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.saveBeforeContractStaffShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "saveBeforeContractStaffShow", $$v)
            },
            expression: "state.saveBeforeContractStaffShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("staff.confirmation.contract_changed"))),
            ]),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("staff.confirmation.title_confirm_remove_contract"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmRemoveContractOk()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.delete")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.confirmRemoveContractShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "confirmRemoveContractShow", $$v)
            },
            expression: "state.confirmRemoveContractShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("staff.confirmation.contract_remove"))),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }